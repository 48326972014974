import React from "react"

const AppHeroSectionD = () => {
    return (
        <div className="isolate" style={{ backgroundColor: '#1a253d' }}>
            <div className="mx-auto max-w-7xl px-6 lg:px-8 py-12 sm:py-16 lg:py-32">
                <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none">
                    <div className="w-full max-w-2xl lg:shrink-0 xl:max-w-2xl">
                        <h1 className="text-4xl font-bold text-gray-200 sm:text-4xl xl:text-5xl">
                            About me
                        </h1>
                        <p className="relative mt-6 text-lg leading-8 text-gray-300 w-full pb-8 lg:pb-8">
                            I always thought of myself as a designer but worked as a UI developer most of my career. 
                            Sparked by the release of CloneX 3D files in 2022 I found a new passion for 3D design. 
                            Before that I had experience with 2D (mostly UI Design) which now feels very limiting compared to working in 3D.
                            
                            <br></br>
                            <br></br>

                            As a metaverse/web3 native I am excited about the growing importance of digital collectibles and lifestyle with a
                            focus on personalization, customization and presentation of avatars across platforms and worlds. 
                            I want to provide more possibilities to do that through my brand wavedashstudio. {/*and in the context of the CloneX universe. */}
                                                        
                            <br></br>
                            <br></br>

                            Why wavedash? I'm fascinated by the game mechanic ever since I discovered it in Rocket League.
                            It represents the key element for everything going forward.
                        </p>
                    </div>
                    <div className="relative flex min-w-xl">
                        <img
                            src="/assets/images/about.webp"
                            loading="lazy" 
                            alt=""
                            className="w-full rounded-xl object-cover shadow-lg"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppHeroSectionD