export default {
    // main: [
    //     { name: 'About', href: '#' },
    //     { name: 'Blog', href: '#' },
    //     { name: 'Jobs', href: '#' },
    //     { name: 'Press', href: '#' },
    //     { name: 'Accessibility', href: '#' },
    //     { name: 'Partners', href: '#' },
    // ],
    social: [
        {
            name: 'X',
            href: 'https://x.com/wavedashstudio',
            hideOnMobile: false,
            icon: (props) => (
                <svg fill="currentColor" width="30" height="30" viewBox="0 0 50 50" {...props}>
                    <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
                </svg>
            ),
        },
        {
            name: 'Instagram',
            href: 'https://www.instagram.com/wavedashstudio',
            hideOnMobile: true,
            icon: (props) => (
                <svg fill="currentColor" width="29" height="29" viewBox="0 0 50 50" {...props}>
                    <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"></path>
                </svg>
            ),
        },
        {
            name: 'YouTube',
            href: 'https://www.youtube.com/@wavedashstudio/videos',
            hideOnMobile: true,
            icon: (props) => (
                <svg fill="currentColor" width="41" height="41" viewBox="0 0 50 50" {...props}>
                    <path d="M 44.898438 14.5 C 44.5 12.300781 42.601563 10.699219 40.398438 10.199219 C 37.101563 9.5 31 9 24.398438 9 C 17.800781 9 11.601563 9.5 8.300781 10.199219 C 6.101563 10.699219 4.199219 12.199219 3.800781 14.5 C 3.398438 17 3 20.5 3 25 C 3 29.5 3.398438 33 3.898438 35.5 C 4.300781 37.699219 6.199219 39.300781 8.398438 39.800781 C 11.898438 40.5 17.898438 41 24.5 41 C 31.101563 41 37.101563 40.5 40.601563 39.800781 C 42.800781 39.300781 44.699219 37.800781 45.101563 35.5 C 45.5 33 46 29.398438 46.101563 25 C 45.898438 20.5 45.398438 17 44.898438 14.5 Z M 19 32 L 19 18 L 31.199219 25 Z"></path>
                </svg>
            ),
        },
    ],
}