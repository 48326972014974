import './style/fonts.css'
import './style/reset.css'
import './style/components.css'
import './style/base.css'
import './style/tailwind.css'
import React from 'react'

import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import AppHeroSectionA from './components/AppHeroSectionA'
import AppHeroSectionB from './components/AppHeroSectionB'
import AppHeroSectionC from './components/AppHeroSectionC'
import AppHeroSectionD from './components/AppHeroSectionD'

export const App = () => {
    return (
        <div className="bg-gray-900">
            <AppHeader />
            <main className="flex-1">
                <AppHeroSectionA />
                <AppHeroSectionB />
                <AppHeroSectionD />
            </main>
            <AppFooter />
        </div>
    )
}
