import React from "react"

import navigation from '../navigation.config'

const AppFooter = () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer className="bg-gray-900">
            <div className="mx-auto max-w-7xl px-6 py-10 flex items-center justify-center flex-col sm:flex-row sm:justify-between">
                <div className="flex justify-center items-center space-x-4">
                    {navigation.social.map((item) => (
                        <a key={item.name} href={item.href} target="_blank" rel="noreferrer" className="text-gray-300 hover:text-gray-100 p-1">
                            <span className="sr-only">{item.name}</span>
                            <item.icon className="h-6 w-6" aria-hidden="true" />
                        </a>
                    ))}
                </div>
                <p className="text-center text-sm leading-5 text-gray-400 mt-4 sm:mt-0">
                    <span className="relative inline-block align-middle top-px text-xl">&copy;</span> {currentYear} wavedashstudio. Icons by <a className="underline" target="_blank" rel="noreferrer" href="https://icons8.com/">Icons8</a>. 
                </p>
            </div>
        </footer>
    )
}

export default AppFooter