import React from "react"
import navigation from '../navigation.config'

const AppHeader = () => {
    return (
        <header className="relative z-10">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1 items-center">
                    <img className="h-5 w-auto mr-4" src="/assets/images/wavedashstudio-logo.png" alt="" />
                    <p className="text-xl text-white font-bold text-nunito-bold">wavedash<span className="text-nunito-light">studio</span></p>
                </div>
                <div className="flex flex-1 justify-end items-center">
                    {navigation.social.map((item) => (
                        <a key={item.name} href={item.href} target="_blank" rel="noreferrer" className={`text-gray-300 hover:text-gray-100 ml-6 ${item.hideOnMobile && 'hidden md:inline-block'}`} >
                            <span className="sr-only">{item.name}</span>
                            <item.icon aria-hidden="true" />
                        </a>
                    ))}
                </div>
            </nav>
        </header>
    )
}

export default AppHeader